import cn from "classnames"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import type { TimelineColumns, TimelineRow, TimelineTotalRow } from "types/graphql"
import { useCsvDownloadListener } from "v2/react/shared/tables/TimelineTable/hooks/useCsvDownloadListener"
import { TimelineHeaderContent } from "v2/react/shared/tables/TimelineTable/TimelineHeaderContent"
import { TimelineTableTd } from "v2/react/shared/tables/TimelineTable/TimelineTableTd"
import { TimelineTableTh } from "v2/react/shared/tables/TimelineTable/TimelineTableTh"
import type { OnCellClick } from "v2/react/shared/tables/TimelineTable/types"
import {
  countRowsInSameGroupUpToIndex,
  getHeaderMinWidth,
  getTooltipMessage,
  inSameGroupUpToIndex,
} from "v2/react/shared/tables/TimelineTable/utils"

type TimelineTableProps = {
  columns: TimelineColumns
  rows: TimelineRow[]
  totals?: TimelineTotalRow[]
  startDate: string
  endDate: string
  onCellClick?: OnCellClick
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  csvDownloadName?: string
  /**
   * Translation path for the tooltip message that appears when the timeline
   * interval does not match the timeline start/end dates. The translation path
   * provided assumed the following nested keys:
   * - timeline_start_and_end_tooltip
   * - timeline_start_tooltip
   * - timeline_end_tooltip
   */
  boundaryMismatchTooltipTranslationPath?: string
}

/**
 * Renders a table of timeline data.
 *
 * Note about row ordering: Rows are expected to be ordered by their `groupByCells`. For example, if a row has a
 * `groupByCells` value of ["A", "a"], all other rows with the ["A", "a"] grouping must come immediately before or after,
 * and any rows starting with the "A" group must come immediately before or after those.
 *
 */
export function TimelineTable({
  columns,
  rows,
  totals,
  startDate,
  endDate,
  onCellClick,
  csvDownloadRef,
  csvDownloadName,
  boundaryMismatchTooltipTranslationPath,
}: TimelineTableProps) {
  const { t } = useTranslation()
  useCsvDownloadListener(csvDownloadRef, columns, rows, totals, csvDownloadName)

  const handleCellClick = useCallback(
    ({
      index,
      rowId,
      metricKey,
      metricLabel,
      groupByCells,
    }: Omit<Parameters<OnCellClick>[0], "intervalStart" | "intervalEnd"> & { index: number }) => {
      if (!onCellClick) return undefined

      const isFirst = index === 0
      const isLast = index === columns.timeline.length - 1
      const currentCol = columns.timeline[index]
      const intervalStart = isFirst ? startDate : currentCol.startDate
      const intervalEnd = isLast ? endDate : currentCol.endDate

      return onCellClick({
        rowId,
        metricKey,
        metricLabel,
        intervalStart,
        intervalEnd,
        groupByCells,
      })
    },
    [columns, endDate, onCellClick, startDate],
  )

  return (
    <div className="timeline-table">
      <table className="h-full bg-white">
        <thead>
          <tr>
            {columns.groupBy.map((cell, index) => (
              <TimelineTableTh
                key={cell.id}
                hideBorderLeft={index === 0}
                className="whitespace-normal"
                style={{
                  minWidth: getHeaderMinWidth(cell.label),
                }}
              >
                {cell.label}
              </TimelineTableTh>
            ))}
            <TimelineTableTh hideBorderLeft={columns.groupBy.length === 0}>
              {t("v2.defaults.metric")}
            </TimelineTableTh>
            {columns.timeline.map((cell, index) => (
              <TimelineTableTh
                key={cell.id}
                className="text-right"
                style={{ width: `${99 / columns.timeline.length}%` }}
                hideBorderRight={index === columns.timeline.length - 1}
              >
                <TimelineHeaderContent
                  tooltipMessage={getTooltipMessage({
                    t,
                    translationPath: boundaryMismatchTooltipTranslationPath,
                    timelineStartDate: startDate,
                    intervalStartDate: cell.startDate,
                    timelineEndDate: endDate,
                    intervalEndDate: cell.endDate,
                    isFirstColumn: index === 0,
                    isLastColumn: index === columns.timeline.length - 1,
                  })}
                  label={cell.label}
                />
              </TimelineTableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.groupByCells.map((value, groupIndex) =>
                !inSameGroupUpToIndex(row, rows[rowIndex - 1], groupIndex) ? (
                  <TimelineTableTd
                    key={columns.groupBy[groupIndex].id}
                    rowSpan={countRowsInSameGroupUpToIndex(rows, row, groupIndex)}
                    className="font-bold"
                    hideBorderLeft={groupIndex === 0}
                    hideBorderBottom={rowIndex === rows.length - 1}
                  >
                    {value}
                  </TimelineTableTd>
                ) : null,
              )}
              <TimelineTableTd
                className="font-bold"
                hideBorderLeft={row.groupByCells.length === 0}
                hideBorderBottom={rowIndex === rows.length - 1}
              >
                {row.metric}
              </TimelineTableTd>
              {row.timelineCells.map((value, index) => (
                <TimelineTableTd
                  key={columns.timeline[index].id}
                  id={`${row.id}-${columns.timeline[index].id}`}
                  className={cn("text-right font-normal", {
                    "hover:cursor-pointer hover:bg-neutral-3": !!onCellClick,
                  })}
                  hideBorderRight={index === row.timelineCells.length - 1}
                  hideBorderBottom={rowIndex === rows.length - 1}
                  onClick={() =>
                    handleCellClick({
                      index,
                      rowId: row.id,
                      metricKey: row.metricKey,
                      metricLabel: row.metric,
                      groupByCells: row.groupByCells,
                    })
                  }
                >
                  {value}
                </TimelineTableTd>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {totals?.map((row, rowIndex) => (
            <tr key={row.metric} className="bg-primary-3-solid">
              {rowIndex === 0 ? (
                <TimelineTableTd
                  className="border-l-0 font-bold uppercase"
                  colSpan={columns.groupBy.length}
                  rowSpan={totals.length}
                >
                  {t("v2.defaults.total")}
                </TimelineTableTd>
              ) : null}
              <TimelineTableTd className="font-bold">{row.metric}</TimelineTableTd>
              {row.timelineCells.map((value, index) => (
                <TimelineTableTd
                  key={columns.timeline[index].id}
                  className={cn("text-right font-normal", {
                    "hover:cursor-pointer hover:bg-neutral-3": !!onCellClick,
                  })}
                  hideBorderRight={index === row.timelineCells.length - 1}
                  onClick={() =>
                    handleCellClick({
                      index,
                      rowId: row.id,
                      metricKey: row.metricKey,
                      metricLabel: row.metric,
                      groupByCells: [],
                    })
                  }
                >
                  {value}
                </TimelineTableTd>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  )
}
