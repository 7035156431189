import cn from "classnames"
import { ChartMenu } from "org_chart/chart/index"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags } from "types/graphql"
import { AdpIntegrationStatusBadge } from "v2/react/components/adp/AdpIntegrationStatusBadge"
import { ImportDropdownProps } from "v2/react/components/imports/ImportDropdown"
import { ApprovalDisplay } from "v2/react/components/orgChart/ChartApproval/ApprovalDisplay"
import ApprovalModals from "v2/react/components/orgChart/ChartApproval/ApprovalModals"
import { GridExportModal } from "v2/react/components/orgChart/exporting"
import { ActionMenu } from "v2/react/components/orgChart/Navigation/Page/ActionMenu"
import { DuplicateButton } from "v2/react/components/orgChart/Navigation/Page/DuplicateButton"
import { ImportExportButton } from "v2/react/components/orgChart/Navigation/Page/ImportExportButton"
import { MergeButton } from "v2/react/components/orgChart/Navigation/Page/MergeButton"
import { OrgChartNavLinks } from "v2/react/components/orgChart/Navigation/Page/OrgChartNavLinks"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlockLarge } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockXSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { selectChartSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

import { ChartExportModal } from "../exporting/ChartExportModal"

interface ApprovalProps {
  currentUser: {
    currentPersonId: number
    canConfigure: boolean
    isCollaborator: boolean
  }
  currentChart: {
    chartId: number
  }
}

export interface OrgChartNavProps {
  abilities: {
    canAddPosition: boolean
    canCreateChart: boolean
    canExportChart: boolean
    canManageChart: boolean
    canManageOfficialChart: boolean
    canManageAdpChangeBatches: boolean
    canViewMetrics: boolean
  }
  approvalProps: ApprovalProps
  ff: FeatureFlags
  importDropdownProps: ImportDropdownProps
  isOfficialChart: boolean
  sharePath: string
  renderedPositionIds?: number[]
  exportAsCSV?: (ids: number[], columns: string[]) => void
}

function OrgChartNav({
  abilities,
  approvalProps,
  ff,
  importDropdownProps,
  isOfficialChart,
  sharePath,
  renderedPositionIds,
  exportAsCSV,
}: OrgChartNavProps) {
  const [exportModalIsOpen, setExportModalIsOpen] = React.useState(false)

  const { t } = useTranslation()
  const { historyMode, displayMode } = useAppSelector(selectChartSettings)

  const {
    canCreateChart,
    canExportChart,
    canManageAdpChangeBatches,
    canManageChart,
    canManageOfficialChart,
  } = abilities

  const isListView = window.location.pathname.indexOf("lists/") > 0
  const showApprovalDisplay =
    ff.positionManagement && !isListView && !isOfficialChart && canManageChart
  const showCollaborators = (ff.multipleCharts || ff.lists) && canManageChart && !isOfficialChart
  const showDuplicateAndMerge = ff.multipleCharts && canManageChart && !isListView

  const shareChart = () => {
    pjaxModalFor(sharePath)
    window.$.pjax.xhr = null
  }

  const openHistory = () => {
    pjaxModalFor(
      UrlHelper.historyLogPath({
        containerType: "chart",
        containerId: window.gon.selected_chart.id,
      }),
    )
    window.$.pjax.xhr = null
  }

  return (
    <div className={cn({ "scenario-view": !isOfficialChart && displayMode !== "cards" })}>
      <PageNav>
        <TitleBlockLarge>
          <ChartMenu />
        </TitleBlockLarge>

        <LinkBlockXSmall>
          <OrgChartNavLinks />
        </LinkBlockXSmall>

        <ActionBlockLarge>
          {isOfficialChart && canManageAdpChangeBatches && (
            <div className="adp-status-badge">
              <AdpIntegrationStatusBadge className="adp-integration-status-badge self-center" />
            </div>
          )}
          {showCollaborators && (
            <button className="bg-transparent" type="button" onClick={shareChart}>
              <div
                className="circles collaborators"
                data-pjax-render="/charts/collaborators"
                data-pjax-container="collaborators"
              />
            </button>
          )}

          {showApprovalDisplay && (
            <div className="approval-display">
              <ApprovalDisplay
                currentUser={approvalProps.currentUser}
                currentChart={approvalProps.currentChart}
                displayType="dropdown"
              />
            </div>
          )}

          {showDuplicateAndMerge && (
            <div className="duplicate-merge gap-2">
              <DuplicateButton
                canCreateChart={canCreateChart}
                chartId={approvalProps.currentChart.chartId}
                type="button"
                disabled={historyMode}
              />
              <MergeButton
                canManageOfficialChart={canManageOfficialChart}
                chartId={approvalProps.currentChart.chartId}
                isOfficialChart={isOfficialChart}
                type="button"
              />
            </div>
          )}

          <div className="import-export">
            <ImportExportButton
              canExportChart={!!(canExportChart && ff.orgchartSharing)}
              canManageOfficialChart={canManageOfficialChart}
              importDropdownProps={importDropdownProps}
              isOfficialChart={isOfficialChart}
              setExportModalIsOpen={setExportModalIsOpen}
            />
          </div>

          <ActionMenu>
            {isOfficialChart && canManageAdpChangeBatches && (
              <div className="adp-status-badge">
                <AdpIntegrationStatusBadge className="self-center" contentOnly />
              </div>
            )}
            {showCollaborators && (
              <>
                <div className="collaborators items-center justify-between gap-4 pb-4 flex">
                  <p className="text-base-bold">{t("v2.orgchart.pagenav.collaborators")}</p>
                  <button className="bg-transparent" type="button" onClick={shareChart}>
                    <div
                      className="circles collaborators"
                      data-pjax-render="/charts/collaborators"
                      data-pjax-container="collaborators"
                    />
                  </button>
                </div>
                <hr className="collaborators-hr mx-[-1rem] mb-4 mt-0" />
              </>
            )}

            {showApprovalDisplay && (
              <>
                <div className="approval-display pb-4">
                  <ApprovalDisplay
                    currentUser={approvalProps.currentUser}
                    currentChart={approvalProps.currentChart}
                    displayType="inline"
                  />
                </div>
                <hr className="approval-display-hr mx-[-1rem] mb-4 mt-0" />
              </>
            )}

            {showDuplicateAndMerge && (
              <div className="duplicate-merge">
                <DuplicateButton
                  canCreateChart={canCreateChart}
                  chartId={approvalProps.currentChart.chartId}
                  type="menu-link"
                  disabled={historyMode}
                />

                <MergeButton
                  canManageOfficialChart={canManageOfficialChart}
                  chartId={approvalProps.currentChart.chartId}
                  isOfficialChart={isOfficialChart}
                  type="menu-link"
                />
              </div>
            )}

            {canExportChart && ff.orgchartSharing && (
              <div className="import-export">
                <ImportExportButton
                  canExportChart={canExportChart}
                  canManageOfficialChart={canManageOfficialChart}
                  importDropdownProps={importDropdownProps}
                  isOfficialChart={isOfficialChart}
                  onlyLinks
                  setExportModalIsOpen={setExportModalIsOpen}
                />
              </div>
            )}

            {canManageChart && ff.orgchartSharing && (
              <button
                className="dropdown-menu-link share"
                onClick={shareChart}
                type="button"
                disabled={historyMode === true}
              >
                <i className="far fa-share-square" />
                <span className="ml-2">{t("v2.orgchart.pagenav.share")}</span>
              </button>
            )}

            {!isListView && canManageChart && (
              <button
                className="dropdown-menu-link history"
                onClick={openHistory}
                type="button"
                disabled={historyMode === true}
              >
                <i className="far fa-history" />
                <span className="ml-2">{t("v2.orgchart.pagenav.history")}</span>
              </button>
            )}
          </ActionMenu>
        </ActionBlockLarge>
        {showApprovalDisplay && <ApprovalModals currentChart={approvalProps.currentChart} />}
        {displayMode === "grid" ? (
          <GridExportModal
            isOpen={exportModalIsOpen}
            onClose={() => setExportModalIsOpen(false)}
            renderedPositionIds={renderedPositionIds}
            exportAsCSV={exportAsCSV}
          />
        ) : (
          <ChartExportModal
            isOpen={exportModalIsOpen}
            onClose={() => setExportModalIsOpen(false)}
            canViewMetrics={abilities.canViewMetrics}
          />
        )}
      </PageNav>
    </div>
  )
}

export { OrgChartNav }
