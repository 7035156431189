import type { Cell, Row, Table } from "@tanstack/react-table"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import {
  useAttachExportListener,
  useRemotelyTriggeredExport,
} from "v2/react/shared/tables/TimelineTable/hooks/useCsvDownloadListener"
import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"

export function useCsvDownloadListener(
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>,
  csvDownloadName?: string,
) {
  const { t } = useTranslation()

  const determineCsvFormatForCell = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (row: Row<any>, cell: Cell<any, unknown>) => {
      const value = cell.getValue() as string | undefined

      if (
        row.original.excluded &&
        cell.id.includes("_type") &&
        value === t("v2.headcount_plan.position_type.new")
      ) {
        return sanitizeCsvValue(`${value} - ${t("v2.headcount_plan.csv.excluded")}`)
      }
      return value ? sanitizeCsvValue(value) : ""
    },
    [t],
  )

  const handleClick = useCallback(() => {
    downloadCsv(
      [
        table
          .getFlatHeaders()
          .filter((header) => header.column.columnDef.header !== "")
          .map((header) => sanitizeCsvValue(header.column.columnDef.header as string))
          .join(","),
        ...table
          // We use the 'PrePagination' row model here to ensure that our CSV
          // download is as close to the current table view as possible,
          // excluding pagination.
          // See: https://tanstack.com/table/latest/docs/guide/row-models#the-order-of-row-model-execution
          .getPrePaginationRowModel()
          .rows.filter((row) => !row.getIsGrouped())
          .map((row) =>
            row
              .getVisibleCells()
              .filter((cell) => cell.column.columnDef.header !== "")
              .map((cell) => determineCsvFormatForCell(row, cell))
              .join(","),
          ),
      ].join("\n"),
      csvDownloadName,
    )
  }, [table, determineCsvFormatForCell, csvDownloadName])

  useAttachExportListener(csvDownloadRef, handleClick)
  useRemotelyTriggeredExport(handleClick)
}
