import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import { useSaveHeadcountPlanPositionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface Props {
  headcountPlanId: string
  reportsTo: { id: string; name: string; title: string; label: string }
  positionId?: string
  participantId?: string
  primary?: boolean
  startDate: string
  refresh?: boolean
}

export function AddNewPositionButton({
  headcountPlanId,
  positionId,
  reportsTo,
  participantId,
  primary,
  startDate,
  refresh,
}: Props) {
  const [mutate, mutation] = useSaveHeadcountPlanPositionMutation()

  React.useEffect(() => {
    if (refresh && mutation.isSuccess) {
      window.location.reload()
    }
  }, [refresh, mutation.isSuccess])

  return (
    <button
      type="button"
      className={`btn--large ${primary ? "btn--primary" : "btn--secondary"}`}
      onClick={() =>
        mutate({
          input: {
            action: "create_new",
            parentPositionId: positionId,
            headcountPlanId,
            headcountPlanParticipantId: participantId,
            revisionNumber: 0,
            payload: {
              title: { id: "custom", label: "new_position_title".t("headcount_plan") },
              start_date: startDate,
              reports_to: reportsTo,
            },
          },
        })
      }
    >
      <FontAwesomeIcon icon={["far", "plus"]} />
      {mutation.isLoading ? "Adding Position..." : "Add Position"}
    </button>
  )
}

const AddNewPositionButtonWithRootProvider = withRootProvider(
  AddNewPositionButton,
  "AddNewPositionButtonWithRootProvider",
)

export { AddNewPositionButtonWithRootProvider }
