import React from "react"

import { HeadcountPlan } from "types/graphql.d"
import { TableDatasheet } from "v2/react/components/headcountPlanning/TableDatasheet"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"

import { HeadcountPlanDatasheetCell } from "./HeadcountPlanDatasheet/HeadcountPlanDatasheetCell"
import { HeadcountPlanDatasheetRow } from "./HeadcountPlanDatasheet/types"

type Props = {
  forEntireOrg?: boolean
  headcountPlanId: string
  headcountPlan: HeadcountPlan | undefined
  participantId?: string
  participantPositionId?: string
  rows: HeadcountPlanDatasheetRow[]
  columns: Column<HeadcountPlanDatasheetRow>[]
  readOnly: boolean
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  csvDownloadName?: string
  showGroupByDropzone?: boolean
}
export function HeadcountPlanDatasheet({
  forEntireOrg,
  headcountPlanId,
  headcountPlan,
  participantId,
  participantPositionId,
  rows,
  columns,
  readOnly,
  csvDownloadRef,
  csvDownloadName,
  showGroupByDropzone,
}: Props) {
  return (
    <TableDatasheet
      renderCell={(row, column) => (
        <HeadcountPlanDatasheetCell
          forEntireOrg={forEntireOrg ?? false}
          row={row}
          column={column}
          headcountPlanId={headcountPlanId}
          headcountPlan={headcountPlan}
          participantId={participantId}
          participantPositionId={participantPositionId}
          readOnly={readOnly}
        />
      )}
      rows={rows}
      columns={columns}
      csvDownloadRef={csvDownloadRef}
      csvDownloadName={csvDownloadName}
      showGroupByDropzone={showGroupByDropzone}
    />
  )
}
