/* eslint-disable react/jsx-props-no-spreading */

import cn from "classnames"
import React from "react"

import { withRootProvider } from "v2/react/components/RootProvider"
import { CompanyMenu, CompanyMenuProps } from "v2/react/shared/navigation/MainNav/CompanyMenu"
import { NavLink, type NavLinkProps } from "v2/react/shared/navigation/MainNav/NavLink"
import { NavSearch, type SearchProps } from "v2/react/shared/navigation/MainNav/NavSearch"
import { NavToggle, type NavToggleProps } from "v2/react/shared/navigation/MainNav/NavToggle"
import { NavUserMenu, NavUserMenuProps } from "v2/react/shared/navigation/MainNav/NavUserMenu"
import { TimerButton } from "v2/react/shared/navigation/MainNav/TimerButton"

interface MainNavProps {
  links: NavLinkProps[]
  companyMenuProps: CompanyMenuProps
  navToggleProps: NavToggleProps
  userMenuProps: NavUserMenuProps
  timerButtonProps: { path: string } | null
  searchProps: SearchProps
}

const Nav = ({
  links,
  companyMenuProps,
  navToggleProps,
  userMenuProps,
  timerButtonProps,
  searchProps,
}: MainNavProps) => (
  <div className={cn("nav", navToggleProps.expanded ? "expand-nav" : "shrink-nav")}>
    <div className="nav-container">
      <div className="mb-6 px-2">
        <div className="mb-2 h-14 items-center flex">
          <CompanyMenu {...companyMenuProps} />
          <NavToggle {...navToggleProps} />
        </div>
        <NavSearch {...searchProps} />
      </div>
      <ul className="flex-col gap-2 flex">
        {links.map((link) => (
          <li key={link.id}>
            <NavLink {...link} />
          </li>
        ))}
      </ul>
      <div className="mb-2 mt-4 flex-col gap-2 p-2 flex">
        {timerButtonProps?.path && <TimerButton path={timerButtonProps.path} />}
        <NavUserMenu {...userMenuProps} />
      </div>
    </div>
    <div className="nav-overlay" />
  </div>
)

const MainNav = withRootProvider(Nav, "MainNav")

export { MainNav }
