import React from "react"

import { AdpIntegrationStatusBadgeStandalone } from "v2/react/components/adp/AdpIntegrationStatusBadge"
import RootProvider from "v2/react/components/RootProvider"
import { useActiveView } from "v2/react/hooks/useActiveView"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { ImportExportButton } from "v2/react/shared/buttons/ImportExportButton"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"

/**
 * See link ids defined in app/helpers/position_management_helper.rb
 */
type PositionsView = "positions" | "job_architecture" | "insights" | "funding_sources" | "settings"

interface Props {
  links: Link<PositionsView>[]
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
  initialView?: PositionsView
  canManageImport?: boolean
}

function PositionsNav({
  links,
  parentTitle,
  parentTitleUrl,
  title,
  initialView,
  canManageImport,
}: Props) {
  const { activeView, handleLinkClick } = useActiveView<PositionsView>({
    initialView: initialView || "positions",
  })
  const isPositionsView = activeView === "positions"

  return (
    <RootProvider>
      <PageNav>
        <TitleBlock>
          {parentTitle ? (
            <TitleHeaderWithParent
              parentTitle={parentTitle}
              parentTitleUrl={parentTitleUrl}
              title={title}
            />
          ) : (
            <h1>{title}</h1>
          )}
        </TitleBlock>
        <LinkBlockSmall>
          <LinkGroup links={links} usePjax onClick={handleLinkClick} />
        </LinkBlockSmall>
        <ActionBlock>
          <AdpIntegrationStatusBadgeStandalone />
          {isPositionsView && !canManageImport && (
            <ExportButton
              exportUrl={UrlHelper.exportPositionsPagePath()}
              useCurrentSearchParamsWithExportUrl
            />
          )}
          {isPositionsView && canManageImport && (
            <ImportExportButton
              exportUrl={UrlHelper.exportPositionsPagePath()}
              importUrl={UrlHelper.importIntroPath()}
              useCurrentSearchParamsWithExportUrl
            />
          )}
        </ActionBlock>
      </PageNav>
    </RootProvider>
  )
}

export { PositionsNav }
