import { useEffect } from "react"

import { HeadcountPlanOwnerHeaderDataQuery } from "types/graphql"
import {
  HeadcountPlanActiveStatus,
  HeadcountPlanParticipantStatus,
  HeadcountPlanRole,
} from "types/graphql.enums"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import {
  useGetHeadcountPlanOwnerHeaderDataQuery,
  useGetHeadcountPlanParticipantHeaderDataQuery,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { setDisplayState } from "v2/redux/slices/HeadcountPlanSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface MinimalHCPMember {
  id: string
  uniqueKey: string
  role: HeadcountPlanRole
  person: {
    id: string
    name: string
    avatarThumbUrl?: string | null
  }
}

interface HCPOwnerHeaderDataProps {
  headcountPlanId: string
  setBudget: boolean | null | undefined
  hasActiveStatus: boolean
  lockedAt: string | null
  canBeFinalized: boolean
  currentUserCanManage?: boolean | null
}

type HCPParticipantHeaderDataProps = {
  participantId: string
  participant: MinimalHCPMember
  collaborators: MinimalHCPMember[]
  hasSubmitted: boolean
  canSubmit: boolean
} & HCPOwnerHeaderDataProps

/**
 * In various spots throughout our HCP module, we server-render components.  For
 * these components, we use data passed through the props from the server for
 * the initial render, but additionally wire in graphql here to ensure that the
 * data is updated when changes are made.
 *
 * The (admittedly awkward) 'current' prefix is meant to indicate that these
 * values are the most up-to-date.
 */
const useHCPOwnerHeaderData = ({
  headcountPlanId,
  setBudget,
  hasActiveStatus,
  lockedAt,
  canBeFinalized,
  currentUserCanManage,
}: HCPOwnerHeaderDataProps) => {
  const ownerData = useGetHeadcountPlanOwnerHeaderDataQuery({ headcountPlanId })

  const {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
  } = processCommonData(
    { setBudget, hasActiveStatus, lockedAt, canBeFinalized, currentUserCanManage },
    ownerData?.data?.headcountPlan,
  )

  return {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
  }
}

/**
 * In various spots throughout our HCP module, we server-render components.  For
 * these components, we use data passed through the props from the server for
 * the initial render, but additionally wire in graphql here to ensure that the
 * data is updated when changes are made.
 *
 * The (admittedly awkward) 'current' prefix is meant to indicate that these
 * values are the most up-to-date.
 */
const useHCPParticipantHeaderData = ({
  headcountPlanId,
  participantId,
  setBudget,
  hasActiveStatus,
  lockedAt,
  canBeFinalized,
  currentUserCanManage,
  participant,
  collaborators,
  hasSubmitted,
  canSubmit,
}: HCPParticipantHeaderDataProps) => {
  const participantQuery = useGetHeadcountPlanParticipantHeaderDataQuery({
    headcountPlanId,
    participantId,
  })

  // Participant-specific data.
  let currentParticipant = participant
  let currentCollaborators = collaborators
  let currentHasSubmitted = hasSubmitted
  let currentCanSubmit = canSubmit

  // Only deviate from the initial props if we have data from graphql.
  if (participantQuery?.data?.headcountPlan) {
    const { headcountPlan } = participantQuery.data

    const { collaborators, ...participant } = headcountPlan.participant
    currentParticipant = participant
    currentCollaborators = collaborators
    currentHasSubmitted =
      headcountPlan.participant.status === HeadcountPlanParticipantStatus.Complete
    currentCanSubmit = headcountPlan.participant.canSubmit
  }

  const {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
  } = processCommonData(
    { setBudget, hasActiveStatus, lockedAt, canBeFinalized, currentUserCanManage },
    participantQuery?.data?.headcountPlan,
  )

  return {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
    currentParticipant,
    currentCollaborators,
    currentHasSubmitted,
    currentCanSubmit,
  }
}

function processCommonData(
  props: Omit<HCPOwnerHeaderDataProps, "headcountPlanId">,
  headcountPlan?: HeadcountPlanOwnerHeaderDataQuery["headcountPlan"],
) {
  let { lockedAt, canBeFinalized, setBudget, hasActiveStatus, currentUserCanManage } = props

  if (headcountPlan) {
    lockedAt = headcountPlan.lockedAt
    canBeFinalized = headcountPlan.canBeFinalized
    setBudget = headcountPlan.setBudget
    hasActiveStatus = headcountPlan.activeStatus === HeadcountPlanActiveStatus.Active
    currentUserCanManage = headcountPlan.currentUserCanManage
  }

  return {
    currentIsFinalized: !!lockedAt,
    currentCanBeFinalized: canBeFinalized,
    currentSetBudget: setBudget,
    currentHasActiveStatus: hasActiveStatus,
    currentCurrentUserCanManage: currentUserCanManage,
  }
}

const useDisplayStateSync = (initialDisplayState: DisplayState) => {
  const dispatch = useAppDispatch()
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? initialDisplayState

  useEffect(() => {
    // Set the display state on initial render.
    if (displayState !== initialDisplayState) {
      dispatch(setDisplayState(initialDisplayState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDisplayState = (newState: DisplayState) => {
    dispatch(setDisplayState(newState))
  }

  return { displayState, updateDisplayState }
}

export type { MinimalHCPMember, HCPParticipantHeaderDataProps, HCPOwnerHeaderDataProps }
export { useHCPOwnerHeaderData, useHCPParticipantHeaderData, useDisplayStateSync }
