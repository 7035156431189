import { TFunction } from "i18next"

import { FeatureFlags } from "types/graphql"
import { ActiveView } from "v2/react/components/headcountPlanning/types"
import { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  isOwner: boolean
  active: string
  headcountPlanId: string
  participantId?: string
  ff: FeatureFlags
  t: TFunction
}

export function getNavLinks({
  isOwner,
  active,
  headcountPlanId,
  participantId,
  ff,
  t,
}: Props): Link<ActiveView>[] {
  if (isOwner) {
    const orgChartLink: Link<ActiveView>[] = ff.hcpOrgchart
      ? [
          {
            text: t("v2.headcount_plan.navigation.org_chart"),
            url: UrlHelper.headcountPlanOwnerOrgChartPath(headcountPlanId),
            active: active === t("v2.headcount_plan.navigation.org_chart"),
            show: true,
            id: "Org Chart",
            disablePjax: true,
          },
        ]
      : []

    return [
      {
        text: t("v2.headcount_plan.navigation.datasheet"),
        url: UrlHelper.headcountPlanOwnerDatasheetPath(headcountPlanId),
        active: active === t("v2.headcount_plan.navigation.datasheet"),
        show: true,
        id: "Datasheet",
      },
      ...orgChartLink,
      {
        text: t("v2.headcount_plan.navigation.timeline"),
        url: UrlHelper.headcountPlanOwnerTimelinePath(headcountPlanId),
        active: active === "Timeline",
        show: true,
        id: "Timeline",
      },
    ]
  }
  if (participantId) {
    const orgChartLink: Link<ActiveView>[] = ff.hcpOrgchart
      ? [
          {
            text: t("v2.headcount_plan.navigation.org_chart"),
            url: UrlHelper.headcountPlanParticipantOrgChartPath(headcountPlanId, participantId),
            active: active === t("v2.headcount_plan.navigation.org_chart"),
            show: true,
            id: "Org Chart",
            disablePjax: true,
          },
        ]
      : []

    return [
      {
        text: t("v2.headcount_plan.navigation.datasheet"),
        url: UrlHelper.headcountPlanParticipantDatasheetPath(headcountPlanId, participantId),
        active: active === t("v2.headcount_plan.navigation.datasheet"),
        show: true,
        id: "Datasheet",
      },
      ...orgChartLink,
      {
        text: t("v2.headcount_plan.navigation.timeline"),
        url: UrlHelper.headcountPlanParticipantTimelinePath(headcountPlanId, participantId),
        active: active === t("v2.headcount_plan.navigation.timeline"),
        show: true,
        id: "Timeline",
      },
    ]
  }

  return []
}
