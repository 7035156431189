import cn from "classnames"
import { TFunction } from "i18next"
import React, { ReactNode } from "react"

import { Position } from "types/graphql"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"

interface PersonSearchResultProps {
  active?: boolean
  avatarUrl: string
  includeListItemClass?: boolean
  personName?: string
  positionTitle: string
  employeeIdDisplay?: ReactNode
  positionIdDisplay?: ReactNode
  bioFieldsDisplay?: ReactNode
}

function PersonSearchResult({
  active,
  avatarUrl,
  includeListItemClass = true,
  personName,
  positionTitle,
  employeeIdDisplay,
  positionIdDisplay,
  bioFieldsDisplay,
}: PersonSearchResultProps) {
  return (
    <div
      className={cn("items-center justify-start gap-1.5 flex", {
        "list-group-item": includeListItemClass,
        highlight: active,
      })}
    >
      <div className="circle circle-med shrink-0">
        {avatarUrl && personName ? (
          <img alt={personName} src={avatarUrl} />
        ) : (
          <BlueIcon icon={["far", "chair-office"]} size="med" />
        )}
      </div>
      <div className="flex-col items-start flex">
        <div className="list-group-item-title break-all text-base-bold">{personName}</div>
        <div className="text-neutral-64">{positionTitle}</div>
        {/* This logic is meant to mirror the search partial
        see: app/views/v2/positions/search.html.slim */}
        {employeeIdDisplay}
        {positionIdDisplay}
        {bioFieldsDisplay}
      </div>
    </div>
  )
}

export default PersonSearchResult

export const maybeShowEmployeeIds = (
  people: Position["people"],
  matchTerm: string,
  t: TFunction,
) => {
  const employeeIds = people?.map((person) => person.employeeId)

  return employeeIds?.some((id) => id?.toLowerCase().includes(matchTerm.toLowerCase())) ? (
    <div className="text-neutral-64">{`${t("v2.people.search.employee_id")}: ${employeeIds.join(
      ", ",
    )}`}</div>
  ) : null
}

export const maybeShowPositionId = (
  positionSystemID: string | undefined | null,
  matchTerm: string,
  t: TFunction,
) =>
  positionSystemID?.toLowerCase().includes(matchTerm.toLowerCase()) ? (
    <div className="text-neutral-64">{`${t(
      "v2.people.search.position_id",
    )}: ${positionSystemID}`}</div>
  ) : null

export const maybeShowBioFields = (people: Position["people"], matchTerm: string, t: TFunction) => {
  const skills = people?.flatMap((person) => person?.bioSkills)
  const licenses = people?.flatMap((person) => person?.bioLicenses)

  const isSkillsMatch = skills?.some(
    (skill) => skill?.name.toLowerCase().includes(matchTerm.toLowerCase()),
  )
  const isLicensesMatch = licenses?.some(
    (license) => license?.name.toLowerCase().includes(matchTerm.toLowerCase()),
  )

  return (
    (isSkillsMatch || isLicensesMatch) && (
      <div className="whitespace-nowrap text-sm">
        {isSkillsMatch && (
          <div className="overflow-hidden text-ellipsis">
            {`${t("v2.people.search.skills")}: ${skills?.map((skill) => skill?.name).join(", ")}`}
          </div>
        )}
        {isLicensesMatch && (
          <div className="overflow-hidden text-ellipsis">
            {`${t("v2.people.search.lic_cert")}: ${licenses
              ?.map((license) => license?.name)
              .join(", ")}`}
          </div>
        )}
      </div>
    )
  )
}
