import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Status } from "v2/react/components/adp/types"

export type GeneralStateContentProps = {
  contentOnly?: boolean
  inSync: boolean
  isMutating: boolean
  isNetworkBusy: boolean
  onReviewChangesClick: () => void
  onSendChangesPress: () => void
  onViewChangeLogClick: () => void
  status: Status
  unsentChangeCount: number
}

const AlertClass = {
  [Status.Caution]: "alert alert-caution",
  [Status.Excite]: "alert alert-pending",
  [Status.Info]: "alert alert-info",
  [Status.Success]: "alert alert-success",
}

export function GeneralStateContent({
  contentOnly,
  inSync,
  isMutating,
  isNetworkBusy,
  onReviewChangesClick,
  onSendChangesPress,
  onViewChangeLogClick,
  status,
  unsentChangeCount,
}: GeneralStateContentProps) {
  const { t } = useTranslation()

  return (
    <>
      <div className={cn({ "p-6": !contentOnly, "p-0 pb-4": contentOnly })}>
        <div className={cn(AlertClass[status], { "min-w-[312px]": contentOnly })}>
          <div className="flex">
            <div className="mr-2 w-4 text-base-bold">
              <FontAwesomeIcon
                icon={inSync ? ["far", "circle-check"] : ["far", "rotate"]}
                size="1x"
              />
            </div>
            <div className="flex-1">
              <div className="text-base-bold">
                {t("v2.adp.change_requests.sync_changes_header", { count: unsentChangeCount })}
              </div>
              <p>
                {t("v2.adp.change_requests.sync_changes_overview", { count: unsentChangeCount })}
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className={cn("my-0", { "mx-[-1rem]": contentOnly })} />
      <div
        className={cn("justify-end gap-2 whitespace-normal py-3 text-left flex", {
          "px-6": !contentOnly,
        })}
      >
        {/* Don't use isNetworkBusy in this first check, fetching doesn't impact this. */}
        {inSync && !isMutating ? (
          <button className="btn btn--secondary" onClick={onViewChangeLogClick} type="button">
            {t("v2.adp.change_requests.sync_changes_view_change_log")}
          </button>
        ) : null}
        {!inSync || isMutating ? (
          <>
            <button className="btn btn--secondary" onClick={onReviewChangesClick} type="button">
              {t("v2.defaults.review_changes")}
            </button>
            <button
              className="btn btn--primary"
              disabled={isNetworkBusy}
              onClick={onSendChangesPress}
              type="button"
            >
              {!isNetworkBusy && <FontAwesomeIcon icon={["far", "paper-plane"]} size="1x" />}
              {!isMutating && t("v2.adp.change_requests.sync_changes_send")}
              {isMutating && t("v2.adp.change_requests.sync_active")}
            </button>
          </>
        ) : null}
      </div>
      {contentOnly && <hr className="mx-[-1rem] mb-4 mt-0" />}
    </>
  )
}
