import {
  autoBatchEnhancer,
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import { GraphqlApi } from "v2/redux/GraphqlApi"
import { listenerMiddleware } from "v2/redux/listenerMiddleware"
import { AbilitySlice } from "v2/redux/slices/AbilitySlice"
import { AdpChangeSlice } from "v2/redux/slices/AdpChangeSlice"
import { ApprovalSlice } from "v2/redux/slices/ApprovalSlice"
import { ContainerSlice } from "v2/redux/slices/ContainerSlice"
import { DrillDownSlice } from "v2/redux/slices/DrillDownSlice"
import { ExportSlice } from "v2/redux/slices/ExportSlice"
import { GraphqlCableSlice } from "v2/redux/slices/GraphqlCableSlice"
import { GridSlice } from "v2/redux/slices/GridSlice"
import { HeadcountPlanSlice } from "v2/redux/slices/HeadcountPlanSlice"
import { MatrixSlice } from "v2/redux/slices/MatrixSlice"
import { NodeSlice } from "v2/redux/slices/NodeSlice"
import { NotificationSlice } from "v2/redux/slices/NotificationSlice"
import { ProfilePanelSlice } from "v2/redux/slices/ProfilePanelSlice"
import { RequisitionSlice } from "v2/redux/slices/RequisitionSlice"
import { SessionSlice } from "v2/redux/slices/SessionSlice"
import { TableFiltersSlice } from "v2/redux/slices/TableFiltersSlice"
import { TableSlice } from "v2/redux/slices/TableSlice"
import { VisualizationSlice } from "v2/redux/slices/VisualizationSlice"

import { DatasheetSlice } from "./slices/DatasheetSlice"
import { FieldSuggestionSlice } from "./slices/FieldSuggestionSlice"
import { SuccessionPlanPanelSlice } from "./slices/SuccessionPlanPanelSlice"

type AppStore = ReturnType<typeof setupStore>
type AppGetState = AppStore["getState"]
type AppDispatch = AppStore["dispatch"]
type RootState = ReturnType<typeof reducer>

const reducer = combineReducers({
  [GraphqlApi.reducerPath]: GraphqlApi.reducer,
  ability: AbilitySlice.reducer,
  adpChange: AdpChangeSlice.reducer,
  approval: ApprovalSlice.reducer,
  container: ContainerSlice.reducer,
  datasheet: DatasheetSlice.reducer,
  drillDown: DrillDownSlice.reducer,
  export: ExportSlice.reducer,
  table: TableSlice.reducer,
  fieldSuggestion: FieldSuggestionSlice.reducer,
  graphqlCable: GraphqlCableSlice.reducer,
  grid: GridSlice.reducer,
  headcountPlan: HeadcountPlanSlice.reducer,
  matrix: MatrixSlice.reducer,
  node: NodeSlice.reducer,
  notification: NotificationSlice.reducer,
  profilePanel: ProfilePanelSlice.reducer,
  session: SessionSlice.reducer,
  successionPlanPanel: SuccessionPlanPanelSlice.reducer,
  requisition: RequisitionSlice.reducer,
  tableFilters: TableFiltersSlice.reducer,
  visualization: VisualizationSlice.reducer,
})

function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(GraphqlApi.middleware),
    enhancers: [
      globalThis?.devToolsExtension
        ? globalThis?.devToolsExtension()
        : (anything: unknown) => anything,
      autoBatchEnhancer({ type: "timer", timeout: 4 }),
    ],
    reducer,
  })
}

// hooks to bypass need for connect() to map state/dispatch to props
const useAppDispatch = () => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { AppDispatch, AppGetState, AppStore, RootState, setupStore, useAppDispatch, useAppSelector }
