/*
The components in this file can be used to create search inputs with a dropdown list
of results. I took the same approach that we do in with Tooltips, where we have a
context provider that holds some state and other components that consume that state.
I think this results in a clean, flexible API.

Here's the general way to use these components:

    <Search>
      <SearchInput />
      <SearchList />
    </Search>

Each component accepts props that allow you to customize its behavior and appearance.
A side-effect of this API is that you can wrap parts of the component tree in other
elements if needed (for example, see `ChartNodeHistorySearchInput` vs `PositionSearchInput`).

Note that these components are not responsible for fetching
data. You should fetch the data you need outside of these components. These
components are only responsible for rendering the search input and list of results
and handling the user's interactions with them.
*/

import classNames from "classnames"
import React from "react"

import { Maybe } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

/*
The `Search` component is a context provider that holds the state for the search
functionality. When building a search input with the components in this file,
you should wrap everything in `Search`.

Notable Props:
- `retainNameOnSelect` - if true, the input field will retain the value of the
  selected item after it is selected. If false/undefined, the input field will be cleared.
- `inputValue`, `setInputValue` - the input value is expected to be controlled outside
  of the Search component so you can use it to search your specific list of items.
- `items` - the list of items to display in the `SearchList` component
- `getId`, `getLabel` - functions to get the id and label of a given item in the list.
  The results are passed to the item click handler and render function (see `renderItem`).
*/
type SearchProps<T> = {
  children: React.ReactNode
  className?: string
} & SearchOptions<T>
export function Search<T>({ children, className, ...options }: SearchProps<T>) {
  const value = useSearch(options)

  return (
    <SearchContext.Provider value={value}>
      <div className={className}>{children}</div>
    </SearchContext.Provider>
  )
}

/*
This component is the actual input field that the user interacts with.

Notable Props:
- `isFocused` - used to automatically focus the input field when it is rendered.
*/
type SearchInputProps = {
  id?: string
  className?: string
  placeholder?: string
  disabled?: boolean
  isFocused?: boolean
}
export function SearchInput({ id, className, placeholder, disabled, isFocused }: SearchInputProps) {
  const { refs, inputValue, getReferenceProps, handleChangeEvent, handleKeyDown } =
    useSearchContext()

  React.useEffect(() => {
    if (refs.domReference.current && isFocused) {
      refs.domReference.current?.focus()
    }
  }, [isFocused, refs.domReference])

  return (
    <input
      aria-autocomplete="list"
      className={classNames("input", className)}
      id={id}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      ref={refs.setReference}
      disabled={disabled}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getReferenceProps({
        onChange: handleChangeEvent,
        onKeyDown: handleKeyDown,
      })}
    />
  )
}

/*
This component is the dropdown list that shows the results of the search.

Notable Props:
- `renderItem` - function that returns the JSX for each item in the list.
  This function receives an object with the item and other useful information.
- `innerClassName`, `outerClassName`, `outerStyle` - The search list is wrapped in two divs. These
  props allow you to add classes + styles to them.
*/
type SearchListProps<T> = {
  renderItem: RenderItemFn<T>
  showEmptyMessage?: boolean
  emptyMessage: string
  outerClassName: string
  innerClassName?: string
  outerStyle?: React.CSSProperties
}
export function SearchList<T>({
  renderItem,
  showEmptyMessage,
  emptyMessage,
  outerClassName,
  innerClassName,
  outerStyle,
}: SearchListProps<T>) {
  const {
    items,
    activeIndex,
    listRef,
    getItemProps,
    inputValue,
    context,
    getFloatingProps,
    refs,
    floatingStyles,
    showResultList,
    handleResultClick,
  } = useSearchContext<T>()
  return (
    <DropdownMenu
      context={context}
      floatingProps={getFloatingProps}
      floatingRef={refs.setFloating}
      floatingStyles={{ ...floatingStyles, ...outerStyle }}
      showList={showResultList && !!inputValue.trim().length}
      wrapperClasses={outerClassName}
    >
      <div className={innerClassName}>
        {items &&
          items.length > 0 &&
          items?.map((item, index) =>
            renderItem({ item, index, activeIndex, listRef, getItemProps, handleResultClick }),
          )}
        {showEmptyMessage && items?.length === 0 && inputValue.length > 0 && (
          <div className="px-4 py-3">
            <p>{emptyMessage}</p>
          </div>
        )}
      </div>
    </DropdownMenu>
  )
}

// Private API - The functions below are only used internally by the components above

type RenderItemFnArgs<T> = {
  item: T
  index: number
  activeIndex: number | null
  listRef: React.MutableRefObject<(HTMLElement | null)[]>
  getItemProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleResultClick: (id: string | null | undefined, label: string) => void
}
export type RenderItemFn<T> = (args: RenderItemFnArgs<T>) => JSX.Element

type SearchContextState<T> = Maybe<ReturnType<typeof useSearch<T>>>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchContext = React.createContext<SearchContextState<any>>(null)

type SearchOptions<T> = {
  floatOverrides?: Record<string, unknown>
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  retainNameOnSelect?: boolean
  items: T[]
  onSelect?: (item: T) => void
  getId: (item: T) => string | null | undefined
  getLabel: (item: T) => string
}
function useSearch<T>({
  floatOverrides,
  inputValue,
  setInputValue,
  retainNameOnSelect,
  items,
  onSelect,
  getId,
  getLabel,
}: SearchOptions<T>) {
  const [showResultList, setShowResultList] = React.useState(false)

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ floatOverrides, showList: showResultList, setShowList: setShowResultList })

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setInputValue(value || "")

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleResultClick = (id: string | null | undefined, label: string) => {
    if (retainNameOnSelect) {
      setInputValue(label)
    } else {
      setInputValue("")
    }

    if (typeof activeIndex === "number" && activeIndex >= 0) {
      const selectedItem = items[activeIndex]
      if (onSelect && selectedItem) onSelect(selectedItem)
    }

    setActiveIndex(null)
    setShowResultList(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && items[activeIndex]) {
      const selectedItem = items[activeIndex]
      handleResultClick(getId(selectedItem), getLabel(selectedItem))
    }
  }

  return {
    items,
    showResultList,
    setShowResultList,
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    handleChangeEvent,
    handleResultClick,
    handleKeyDown,
    inputValue,
  }
}

function useSearchContext<T>() {
  const context = React.useContext<SearchContextState<T>>(SearchContext)
  if (!context) {
    throw new Error("Search components must be wrapped in <Search />")
  }
  return context
}
