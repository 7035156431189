import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useHCPOwnerHeaderData } from "v2/react/components/headcountPlanning/hooks/useHCPHeaderData"
import { NavActiveIcon } from "v2/react/components/headcountPlanning/shared/NavActiveIcon"
import RootProvider, { withRootProvider } from "v2/react/components/RootProvider"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import { LoadingWrapper } from "v2/react/shared/loaders/LoadingWrapper"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie, setCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetHeadcountPlanWithTreeQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { Notifications } from "./Overview/Notifications"
import { ParticipantTable } from "./Overview/ParticipantTable"
import { PlanTopbarMenu } from "./shared/PlanTopbarMenu"

interface Props {
  headcountPlanId: string
  rootPositionId: string | undefined
  participantIds: string[]
  maxChartDepth: number
}

function WithProvider({ headcountPlanId, rootPositionId, participantIds, maxChartDepth }: Props) {
  const { data, isError } = useGetHeadcountPlanWithTreeQuery({
    id: headcountPlanId,
    maxChartDepth,
    rootPositionId,
    participantIds,
  })
  const headcountPlan = data?.headcountPlan
  const cookieName = `built__dismissed-invite-notification-for-hcp-${headcountPlanId}`
  const [showNotifications, setShowNotifications] = useState(getCookie(cookieName) !== "true")
  const isFinalized = !!headcountPlan?.lockedAt

  const handleNotificationsCloseClick = () => {
    setShowNotifications(false)
    setCookie(cookieName, "true", 365 * 5) // 5 years since we'll have another solution by then
  }

  return (
    <LoadingWrapper isLoading={!headcountPlan} isError={isError}>
      {!!headcountPlan && (
        <div className="p-12">
          <Notifications
            headcountPlan={headcountPlan}
            onClose={handleNotificationsCloseClick}
            showNotifications={showNotifications}
          />
          <ParticipantTable
            showEntireOrgRow={headcountPlan.currentUserCanManage}
            headcountPlanId={headcountPlan.id}
            logoThumbUrl={headcountPlan.company.logoThumbUrl || ""}
            companyName={headcountPlan.company.name || ""}
            totalBudget={headcountPlan.formattedBudget}
            totalProposal={headcountPlan.formattedProposedBudget}
            totalRemainingBudget={headcountPlan.remainingBudget}
            participants={headcountPlan.participantsTree}
            showBudget={headcountPlan.setBudget}
            showProposal={headcountPlan.setBudget && !isFinalized}
            canBeFinalized={headcountPlan.canBeFinalized}
          />
        </div>
      )}
    </LoadingWrapper>
  )
}

const Overview = (props: Props) => (
  <RootProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <WithProvider {...props} />
  </RootProvider>
)

const Header = ({
  name,
  id,
  lockedAt,
  currentUserCanManage,
  canBeFinalized,
  hasActiveStatus,
  setBudget,
}: {
  name: string
  id: string
  lockedAt: string | null
  currentUserCanManage: boolean
  canBeFinalized: boolean
  hasActiveStatus: boolean
  setBudget: boolean
}) => {
  const { t } = useTranslation()
  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)

  const {
    currentIsFinalized,
    currentCanBeFinalized,
    currentSetBudget,
    currentHasActiveStatus,
    currentCurrentUserCanManage,
  } = useHCPOwnerHeaderData({
    headcountPlanId: id,
    setBudget,
    hasActiveStatus,
    lockedAt,
    canBeFinalized,
    currentUserCanManage,
  })

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={name || ""}
          subtitle={t("v2.headcount_plan.headcount_planning")}
          subtitleLinkTo={UrlHelper.headcountPlansPath()}
        />
      </TitleBlockLarge>
      <ActionBlock>
        <div className="items-center gap-2 flex">
          {currentHasActiveStatus ? <NavActiveIcon /> : null}
          {!currentCurrentUserCanManage || currentIsFinalized ? null : (
            <>
              <ClientOnly>
                <FinalizePlanModal
                  headcountPlanId={id}
                  headcountPlanName={name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </ClientOnly>

              <FinalizePlanButton
                canBeFinalized={currentCanBeFinalized}
                setIsOpen={setFinalizePlanModalIsOpen}
              />
            </>
          )}
          {!currentCurrentUserCanManage || currentIsFinalized ? null : (
            <PlanTopbarMenu
              headcountPlanId={id}
              isFinalized={currentIsFinalized}
              setBudget={!!currentSetBudget}
            />
          )}
        </div>
      </ActionBlock>
    </PageNav>
  )
}

const OverviewHeader = withRootProvider(Header, "OverviewHeader")

export { Overview, OverviewHeader }
