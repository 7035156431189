import React from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import PersonSearchResult, {
  maybeShowBioFields,
  maybeShowEmployeeIds,
  maybeShowPositionId,
} from "v2/react/shared/forms/PersonSearchResult"

interface AutocompleteResultProps {
  className: string
  getItemProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleResultClick: (
    uniqueKey: string | undefined | null,
    positionLabel: string,
    ev: React.MouseEvent,
  ) => void
  isSelected: boolean
  position: Position
  /**
   * string <'joined' | 'singular'>: determines how to return the position label
   * as a singular value of <name | title | id>
   * or as a joined display of <names>, <title || id>.
   * Default is 'joined'
   */
  positionLabelFormat?: "joined" | "singular"
  refFn: (node: HTMLDivElement | null) => void
  searchTerm: string
}

const AutocompleteResult: React.FC<AutocompleteResultProps> = ({
  className,
  getItemProps,
  handleResultClick,
  isSelected,
  position,
  positionLabelFormat,
  refFn,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  const imageUrl = people?.[0]?.avatarThumbUrl
  const positionLabel = {
    joined: [peopleNames, positionTitle || positionSystemID].filter((n) => n).join(", "),
    singular: peopleNames || positionTitle || positionSystemID || "",
  }

  const matchTerm = searchTerm?.toLowerCase()

  return (
    <div
      role="option"
      aria-selected={isSelected}
      key={position.uniqueKey}
      className={className}
      ref={refFn}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getItemProps({
        onClick: (e) =>
          handleResultClick(position.uniqueKey, positionLabel[positionLabelFormat || "joined"], e),
      })}
    >
      <PersonSearchResult
        active={isSelected}
        avatarUrl={imageUrl || ""}
        personName={peopleNames}
        positionTitle={positionTitle || ""}
        employeeIdDisplay={maybeShowEmployeeIds(people, matchTerm, t)}
        positionIdDisplay={maybeShowPositionId(positionSystemID, matchTerm, t)}
        bioFieldsDisplay={maybeShowBioFields(people, matchTerm, t)}
      />
    </div>
  )
}

export { AutocompleteResult }
