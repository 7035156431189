import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { TimelineColumns, TimelineRow, TimelineTotalRow } from "types/graphql"
import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"
import { resetTriggerExport } from "v2/redux/slices/ExportSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

export function useCsvDownloadListener(
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined | string,
  columns: TimelineColumns,
  rows: TimelineRow[],
  totals?: TimelineTotalRow[],
  csvDownloadName?: string,
) {
  const { t } = useTranslation()
  const handleClick = useCallback(() => {
    const combinedColumns = [
      ...columns.groupBy.map((col) => sanitizeCsvValue(col.label)),
      t("v2.defaults.metric"),
      ...columns.timeline.map((col) => sanitizeCsvValue(col.label)),
    ]

    const csvData = [
      combinedColumns.join(","),
      ...rows.map((row) => {
        const groupByCells = row.groupByCells.map((cell) => sanitizeCsvValue(cell))
        const metric = sanitizeCsvValue(row.metric)
        const timelineCells = row.timelineCells.map((cell) => sanitizeCsvValue(cell))
        return [...groupByCells, metric, ...timelineCells].join(",")
      }),
    ]

    if (totals && totals.length > 0) {
      const totalsData = totals.map((total) => {
        const totalCells = [t("v2.defaults.total"), ...columns.groupBy.slice(1).map(() => "")]
        const metric = sanitizeCsvValue(total.metric)
        const timelineCells = total.timelineCells.map((cell) => sanitizeCsvValue(cell))
        return [...totalCells, metric, ...timelineCells].join(",")
      })
      csvData.push(...totalsData)
    }

    downloadCsv(csvData.join("\n"), csvDownloadName)
  }, [columns, rows, totals, t, csvDownloadName])

  useAttachExportListener(csvDownloadRef, handleClick)
  useRemotelyTriggeredExport(handleClick)
}

const getButtonRef = (csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined | string) => {
  if (typeof csvDownloadRef === "string") {
    return document.querySelector(`#${csvDownloadRef}`)
  }

  return csvDownloadRef?.current
}

const useAttachExportListener = (
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined | string,
  handleClick: () => void,
) => {
  useEffect(() => {
    const button = getButtonRef(csvDownloadRef)

    button?.addEventListener("click", handleClick)
    return () => button?.removeEventListener("click", handleClick)
  }, [csvDownloadRef, handleClick])
}

const useRemotelyTriggeredExport = (handleClick: () => void) => {
  const dispatch = useAppDispatch()
  const triggerExport = useAppSelector((state) => state.export.triggerExport)

  useEffect(() => {
    if (triggerExport > 0) {
      handleClick()
    }

    return () => {
      if (triggerExport > 0) dispatch(resetTriggerExport())
    }
  }, [handleClick, triggerExport, dispatch])
}

export { getButtonRef, useAttachExportListener, useRemotelyTriggeredExport }
