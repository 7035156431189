import classNames from "classnames"
import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { defaultMemoize } from "reselect"
import { useOnClickOutside } from "usehooks-ts"

import type { Maybe, Option } from "types/graphql"
import { useStandardCellInputMouseDownHandler } from "v2/react/components/orgChart/Datasheet/Cell/hooks"
import type {
  Column,
  CursorConnection,
  NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { TransitionKeys } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/cursorKeyMovements"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

type PrepareValueArg =
  | { type: "suggestion"; id: string; label: string }
  | { type: "custom"; inputValue: string }
  | { type: "noChange"; inputValue: string }
  | { type: "blank" }

type PrepareValueContext = { allowCustomInput: boolean; currentValue?: string }
type PrepareValueHandler = (arg: PrepareValueArg, context: PrepareValueContext) => string

interface TextCompleteProps<TNode, CType> {
  callerManagedOptions?: boolean
  callerOptions?: Option[]
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  column: Column<CType>
  fallbackOption?: React.ReactElement
  isFirst: boolean
  isLast: boolean
  noBorder?: boolean
  style: CSSProperties
  html: string | null
  focusCell: boolean
  allowCustomInput?: boolean
  prepareValue?: PrepareValueHandler
  onInputValueChange?: (value: string) => void
}

const defaultPrepareValue = (arg: PrepareValueArg, context: PrepareValueContext) => {
  switch (arg.type) {
    case "suggestion":
      return context.allowCustomInput ? arg.label : arg.id
    case "custom":
      return arg.inputValue
    case "noChange":
      return arg.inputValue
    default:
      // Default case is for "blank"
      return ""
  }
}

function CollectionAutocomplete<TNode, CType = TNode>({
  callerManagedOptions,
  callerOptions,
  cursorConnection,
  row,
  column,
  fallbackOption,
  isFirst,
  isLast,
  noBorder,
  style,
  html,
  allowCustomInput = false,
  onInputValueChange,
  prepareValue = defaultPrepareValue,
}: TextCompleteProps<TNode, CType>) {
  const [inputValue, setInputValue] = useState(html || "")
  const [showResultList, setShowResultList] = useState(false)

  const { collectionResult: fromSearch, returnEmpty } = useCollectionSearch({
    fieldKey: column.fieldKey as FieldKey, // TODO MY: Harden this
    filter: inputValue.trim(),
    skipQuery: callerManagedOptions,
  })

  const collectionResult = callerOptions ?? fromSearch

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  // `refs.domReference` is stable and isn't expected to change across renders.
  useEffect(() => {
    const { current: currentInput } = refs.domReference

    const noRefOrNoChangeInValue = !currentInput || currentInput.value === html
    const inputIsFocused = document.activeElement === currentInput
    if (noRefOrNoChangeInValue || inputIsFocused) return

    setInputValue(html ?? "")
  }, [html, refs.domReference])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setInputValue(value)
    setShowResultList(value.length > 0)
    setActiveIndex(!allowCustomInput && value.length > 0 ? 0 : null)
    onInputValueChange?.(value)
  }

  const handleSubmit = (value: string, event?: KeyboardEvent) => {
    const trimmedValue = value.trim()
    refs.domReference.current?.blur()
    setShowResultList(false)
    const cursorOptions = event
      ? { moveAfterByEvent: event.nativeEvent }
      : { transitionKeyCanMove: true }

    if (trimmedValue === html) cursorConnection.stopWriting(cursorOptions)
    else cursorConnection.saveWrite(trimmedValue, cursorOptions)
  }

  /**
   * @note `activeIndex` is defined on hover, so it may not be an adequate value
   *   for checking if the value encapsulated by this component is indeed a
   *   result choice.
   */
  const getValue = useCallback(() => {
    const context = { allowCustomInput }
    const currentValue = (html ?? "").trim()
    const suggestion = collectionResult[activeIndex ?? -1]
    const input = suggestion ? suggestion.label : inputValue.trim()

    if (currentValue === input)
      return prepareValue({ type: "noChange", inputValue: currentValue }, context)
    if (suggestion) return prepareValue({ type: "suggestion", ...suggestion }, context)
    if (input === "") return prepareValue({ type: "blank" }, context)
    return prepareValue({ type: "custom", inputValue }, context)
  }, [allowCustomInput, html, inputValue, collectionResult, activeIndex, prepareValue])

  useImperativeHandle(
    cursorConnection.cellInputRef,
    () => ({
      blur: () => {
        setShowResultList(false)
        refs.domReference.current?.blur()
      },
      focus: (initial?: string) => {
        if (typeof initial !== "undefined") setInputValue(initial)
        if (refs.domReference.current && initial) {
          refs.domReference.current.value = initial
        }
        refs.domReference.current?.focus()
        setShowResultList(true)
      },
      getValue,
    }),
    [getValue, setInputValue, refs.domReference, setShowResultList],
  )

  const handleEnterAsSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (activeIndex === null) {
      cursorConnection.keyUpListenerWhileWriting(event)
    } else if (TransitionKeys.matchEvent(event.nativeEvent)) {
      event.preventDefault()
      setTimeout(handleExecutingSubmit, 0, event)
    }
  }

  const handleResultChoice = (option: Option, event?: KeyboardEvent) => {
    setInputValue(option.label)
    // This is done separately from `getValue` in order to account for
    // `activeIndex` possibly being `null`.
    const value =
      option.label.trim() === (html ?? "").trim()
        ? prepareValue({ type: "noChange", inputValue: html ?? "" }, { allowCustomInput })
        : prepareValue({ type: "suggestion", ...option }, { allowCustomInput })
    handleSubmit(value, event)
  }

  const makeChooseResultHandler = (option: Option) => (ev: React.MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    handleResultChoice(option)
  }

  const handleExecutingSubmit = (event?: KeyboardEvent) => {
    setActiveIndex(null)
    setShowResultList(false)

    const clickedDropdownResult = activeIndex !== null && collectionResult[activeIndex]
    if (clickedDropdownResult) {
      handleResultChoice(clickedDropdownResult, event)
    } else if (allowCustomInput) {
      handleSubmit(getValue(), event)
    } else {
      setInputValue(html || "")
    }
  }

  const handleMouseDown = useStandardCellInputMouseDownHandler(refs.domReference)
  const handleBlur = () => handleExecutingSubmit()
  useOnClickOutside(refs.domReference, handleBlur)

  return (
    <>
      <input
        style={prepareStyle(style, isFirst, row.color, noBorder)}
        className={nodeClassName(isLast)}
        aria-autocomplete="list"
        value={inputValue}
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onKeyUp: handleEnterAsSubmit,
          onChange: handleChange,
          onMouseDown: handleMouseDown,
        })}
      />
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={context}
      >
        <div className="list-group autocomplete-list">
          {collectionResult?.map((option, index) => (
            <div
              role="option"
              aria-selected={activeIndex === index}
              key={option.id}
              className={autocompleteClassName(activeIndex, index)}
              ref={(node) => {
                listRef.current[index] = node
              }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...getItemProps({
                onClick: makeChooseResultHandler(option),
              })}
            >
              <div className="truncate">{option.label}</div>
            </div>
          ))}
          {!allowCustomInput &&
            !returnEmpty &&
            collectionResult?.length === 0 &&
            !fallbackOption &&
            inputValue.length > 0 && (
              <div className="AutocompleteField__no-result">
                <p className="AutocompleteField__no-result-text">
                  {getNoneLabel(String(column.fieldKey))}
                </p>
              </div>
            )}
          {fallbackOption}
        </div>
      </DropdownMenu>
    </>
  )
}

const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell Cell__select-field bg-transparent", { last: isLast })
const autocompleteClassName = (activeIndex: number | null, index: number) =>
  classNames("list-group-item", { highlight: activeIndex === index })

const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>, noBorder?: boolean) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  if (noBorder) {
    return {
      ...base,
      borderRightWidth: 0,
      borderTopWidth: 0,
      zIndex: 21,
    }
  }
  return { ...base, zIndex: 21 }
})

const getNoneLabel = (fieldKey: string) => {
  let label = `none_found_${fieldKey}`.t("org_chart")
  if (label === `none_found_${fieldKey}`) {
    label = "none_found_backup".t("org_chart")
  }
  return label
}

export { CollectionAutocomplete, PrepareValueHandler }
