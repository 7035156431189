import { ControlElement, ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"
import { useCurrentSession } from "v2/react/hooks/useSessionHooks"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { AutocompleteResult } from "v2/react/shared/forms/AutocompleteResult"
import { useValidation } from "v2/react/shared/jsonForms/JsonFormControls/hooks/useValidation"
import { JsonFormControlLabel } from "v2/react/shared/jsonForms/JsonFormControls/JsonFormControlLabel"
import { useJsonFormControlsOptions } from "v2/react/shared/jsonForms/JsonFormControlsOptions"

import { PositionInput } from "./PositionInput"
import { PositionInputDisplay } from "./PositionInputDisplay"

const JsonPositionInput: React.FC<ControlProps> = ({
  config,
  data,
  enabled,
  id,
  handleChange,
  label,
  path,
  schema,
  uischema,
  visible,
  errors,
}) => {
  const { t } = useTranslation()
  const { showError, errorMessage } = useValidation({
    path,
    schema,
    schemaError: errors,
    submitting: config.submitting,
  })
  const [isEditing, setIsEditing] = useState((data?.name || "") === "")
  const [inputValue, setInputValue] = useState("")
  const [showResultList, setShowResultList] = useState(false)
  const didMountRef = useRef(false)

  const { positions, returnEmpty, searchTerm } = usePositionSearchWithOptions(inputValue, uischema)

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (!visible) setInputValue("")
  }, [visible])

  useEffect(() => {
    // Prevent focusing the position selector on the first render of the initial
    // form load, only focus on subsequent enabling of the input.
    if (didMountRef.current === false) {
      didMountRef.current = true
      return
    }

    if (isEditing && refs.domReference?.current) {
      refs.domReference.current?.focus()
    } else if (!isEditing && refs.domReference?.current) {
      refs.domReference.current?.blur()
    }
  }, [isEditing, refs.domReference])

  useEffect(() => {
    setInputValue(data?.name || "")
  }, [data, enabled])

  const handleBlurEvent = (event: React.FocusEvent<HTMLInputElement>) => {
    // On select this blur is called before the result handlers. If the related
    // target (thing getting the focus) is a selection (contains class
    // AutocompleteField) then don't reset and defer to the result handlers.
    if (event.relatedTarget && event.relatedTarget.className.includes("autocomplete-container"))
      return
    setIsEditing(false)
  }

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value || "")

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      // NOTE: This is needed to reset the validation/etc for JsonForm lib's
      // updates.
      handleChange(path, { name: "", id: "" })
      setShowResultList(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && positions[activeIndex]) {
      const position = positions[activeIndex]
      handleResultClick(position.uniqueKey, getPositionLabel(positions[activeIndex]))
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  const handleResultClick = (positionId: string | null | undefined, positionLabel: string) => {
    setInputValue(positionLabel)
    setActiveIndex(null)
    setShowResultList(false)
    handleChange(path, { name: positionLabel, id: positionId })
    setIsEditing(false)
  }

  const handleClearValue = () => {
    setInputValue("")
    handleChange(path, { name: "", id: "" })
    setIsEditing(true)
    if (refs.domReference?.current) {
      refs.domReference.current?.focus()
    }
  }

  const handlePositionLabelClick = () => {
    setIsEditing(true)
  }

  if (!visible) return null

  if (showAsLabel(isEditing, enabled, inputValue)) {
    const position = activeIndex ? positions[activeIndex] : null
    const inputValueAsLabel = position
      ? `${position.title}, ${position.systemIdentifier}`
      : inputValue

    return (
      <PositionInputDisplay
        enabled={enabled}
        errorMessage={errorMessage}
        handleClearValue={handleClearValue}
        handlePositionLabelClick={handlePositionLabelClick}
        inputLabel={<JsonFormControlLabel id={id} label={label} uischema={uischema} />}
        positionId={data?.id || ""}
        positionLabel={inputValueAsLabel}
        showError={showError}
      />
    )
  }

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <PositionInput
        enabled={enabled}
        errorMessage={errorMessage}
        getReferenceProps={getReferenceProps}
        handleBlurEvent={handleBlurEvent}
        handleChangeEvent={handleChangeEvent}
        handleClearValue={handleClearValue}
        handleKeyDown={handleKeyDown}
        id={id}
        inputLabel={<JsonFormControlLabel id={id} label={label} uischema={uischema} />}
        inputValue={inputValue}
        positionName={data?.name || ""}
        refs={refs}
        showError={showError}
      />
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={context}
      >
        <div className="list-group autocomplete-list">
          {positions &&
            positions.length > 0 &&
            positions?.map((position, index) => (
              <AutocompleteResult
                key={position.uniqueKey}
                className={positionClassName(activeIndex, index)}
                getItemProps={getItemProps}
                handleResultClick={handleResultClick}
                isSelected={activeIndex === index}
                position={position}
                refFn={(node: HTMLDivElement | null) => {
                  listRef.current[index] = node
                }}
                searchTerm={searchTerm}
              />
            ))}
          {!returnEmpty && positions?.length === 0 && inputValue.length > 0 && (
            <p className="p-2 text-neutral-64">{t("v2.positions.index.positions_found.zero")}</p>
          )}
        </div>
      </DropdownMenu>
    </div>
  )
}

const positionClassName = (activeIndex: number | null, index: number) =>
  classNames({ highlight: activeIndex === index })

const getPositionLabel = (position: Position) => {
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  return [peopleNames, positionTitle || positionSystemID].filter((n) => n).join(", ")
}

const showAsLabel = (isEditing: boolean, enabled: boolean, inputValue: string): boolean => {
  if (!enabled) return true
  if (inputValue === "") return false
  return !isEditing
}

const usePositionSearchWithOptions = (inputValue: string, uischema: ControlElement) => {
  const { positionControl } = useJsonFormControlsOptions()
  const { officialChartKey, roleScopes, isLimitedAdmin } = useCurrentSession()

  const options: Parameters<typeof usePositionSearch>[0] = {
    chartKey: officialChartKey,
    filter: inputValue,
    excludeWithReqs: uischema.options?.excludeWithReqs || false,
  }

  if (positionControl.customized) {
    options.startingFromPositionId = positionControl.startingFromPositionKey
    options.subordinateOnly = positionControl.subordinateOnly
    options.includeSelf = positionControl.includeSelf
  } else if (isLimitedAdmin || roleScopes.length === 0) {
    options.subordinateOnly = uischema.options?.subordinateOnlyIfApplicable
    options.includeSelf = uischema.options?.includeSelfIfApplicable
  }

  return usePositionSearch(options)
}

export { JsonPositionInput }
