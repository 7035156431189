import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface InfoProps {
  description: string
  tooltipSize?: "sm"
}

export const Info = ({ description, tooltipSize }: InfoProps) => (
  <Tooltip>
    <TooltipTrigger className="tooltip-description">
      <div className="icon">
        <FontAwesomeIcon icon={["far", "circle-info"]} />
      </div>
    </TooltipTrigger>
    <TooltipContent
      className={tooltipSize ? `react-tooltip-content--${tooltipSize}` : "react-tooltip-content"}
    >
      {description}
    </TooltipContent>
  </Tooltip>
)
