import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { pjaxModalFor } from "v2/react/utils/pjax"

interface UserMenuLink {
  text: string
  icon: IconProp
  url: string
  show: boolean
  addDividerAbove?: boolean
  usePjaxModal?: boolean
}

interface NavUserMenuProps {
  links: UserMenuLink[]
  currentPerson: { name: string; avatarThumbUrl: string }
}

function NavUserMenu({ links, currentPerson }: NavUserMenuProps) {
  const [showMenu, setShowMenu] = useState(false)
  const {
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
  } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "top-start" },
  })

  if (!currentPerson) return null

  const handleOpen = () => {
    refs.domReference.current?.focus()
    setActiveIndex(showMenu ? null : 0)
  }

  return (
    <div className="dropdown--react person-menu w-full items-center justify-center flex">
      <button
        type="button"
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({ onClick: handleOpen })}
        className="dropdown-link person-dropdown-link w-full items-center rounded bg-transparent p-1 text-left hover:bg-neutral-3"
      >
        {currentPerson.avatarThumbUrl && (
          <img className="circle circle-sm" src={currentPerson.avatarThumbUrl} alt="user avatar" />
        )}
        <div className="hide w-full max-w-[165px] items-center justify-between gap-1 flex">
          <p className="mt ml-2 truncate">{currentPerson.name}</p>
          <FontAwesomeIcon icon="caret-down" />
        </div>
      </button>
      <DropdownMenu
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, minWidth: "10rem", left: 0 }}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu person-dropdown-menu !block z-10 -mt-2"
        context={context}
      >
        {links.map((link, index) =>
          link.show ? (
            <span key={link.url}>
              {link.addDividerAbove && <hr className="-mx-2 my-2" />}
              <a
                href={link.url}
                className="nav-menu-link whitespace-nowrap"
                onClick={
                  link.usePjaxModal
                    ? (event) => {
                        event.preventDefault()
                        setShowMenu(false)
                        pjaxModalFor(link.url)
                      }
                    : undefined
                }
                ref={(node) => {
                  listRef.current[index] = node
                }}
              >
                <span className="mr-0.5">
                  <FontAwesomeIcon icon={link.icon} />
                </span>
                {link.text}
              </a>
            </span>
          ) : (
            <span />
          ),
        )}
      </DropdownMenu>
    </div>
  )
}

export type { NavUserMenuProps }
export { NavUserMenu }
