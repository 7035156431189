import { skipToken } from "@reduxjs/toolkit/dist/query"
import React from "react"

import { BuilderContainer } from "v2/react/components/careerLadders/BuilderContainer"
import { Description } from "v2/react/components/careerLadders/Show/Description"
import { Stats } from "v2/react/components/careerLadders/Show/Stats"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { useCareerLadderDetailsQuery } from "v2/redux/GraphqlApi/CareerLaddersApi"

interface CareerLaddersIndexProps {
  careerLadderId?: string
}

function WithProvider({ careerLadderId }: CareerLaddersIndexProps) {
  const id = careerLadderId || "career_ladder_1"
  const { data: careerLadderData, isLoading } = useCareerLadderDetailsQuery(
    id ? { uniqueKey: id } : skipToken,
  )

  if (!careerLadderData || !careerLadderData.careerLadderDetails) return <Spinner />

  const careerLadder = careerLadderData.careerLadderDetails
  const stats = careerLadder.stats || []

  return (
    <div>
      <div className="page-pad">
        <div className="grid-cols-1 gap-3 grid lg:grid-cols-[2fr_1fr] lg:gap-8">
          <div>
            <Description careerLadder={careerLadderData.careerLadderDetails} />
            {careerLadderData && (
              <BuilderContainer
                careerLadderUniqueKey={careerLadderId || ""}
                positionTypeUniqueKey=""
                showEditToggle
              />
            )}
          </div>
          <div>{stats?.length > 0 && <Stats stats={stats} isLoading={isLoading} />}</div>
        </div>
      </div>
    </div>
  )
}

function Show(props: CareerLaddersIndexProps) {
  return (
    <RootProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WithProvider {...props} />
    </RootProvider>
  )
}

export { Show }
