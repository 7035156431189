/* eslint-disable no-nested-ternary */
import { FloatingPortal, useFloating } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "usehooks-ts"

import { useDisplayStateMethods } from "v2/react/components/headcountPlanning/hooks/useDisplayStateMethods"
import { ActiveView } from "v2/react/components/headcountPlanning/types"
import { ClientOnly } from "v2/react/shared/layout/ClientOnly"
import { prepareIconClass } from "v2/react/utils/misc"
import { UrlHelper } from "v2/react/utils/urls"
import { incrementTriggerExport } from "v2/redux/slices/ExportSlice"
import { useAppDispatch } from "v2/redux/store"

import { FinalizePlanButton, FinalizePlanModal } from "../Overview/FinalizePlanButton"

type Props = {
  displayState?: "original" | "approved"
  headcountPlanId: string
  isFinalized: boolean
  setBudget?: boolean
  setDisplayState?: (value: "original" | "approved") => void
  csvDownloadRef?: React.RefObject<HTMLButtonElement> | string
  setRef?: (element: HTMLButtonElement) => void
  view?: ActiveView
  /**
   * Shows the button if we're below the `sm` breakpoint.
   */
  showExportButton?: boolean
  /**
   * Shows the button if we're below the `sm` breakpoint.
   */
  showFinalizeButton?: boolean
}
export function PlanTopbarMenu({
  displayState,
  headcountPlanId,
  isFinalized,
  setBudget,
  setDisplayState,
  csvDownloadRef,
  setRef,
  view,
  showExportButton,
  showFinalizeButton,
}: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = React.useState(false)
  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = React.useState(false)
  const { refs, floatingStyles } = useFloating({ placement: "bottom-end" })
  const isTimeline = view === "Timeline"

  useOnClickOutside(refs.floating, (event) => {
    if (refs.domReference?.current?.contains(event.target as Node)) return
    setIsOpen(false)
  })

  const { showOriginalView, showApprovedView } = useDisplayStateMethods({
    setDisplayState,
    headcountPlanId,
    setIsOpen,
  })

  const handleExportButtonClick = () => {
    if (typeof csvDownloadRef === "string") {
      dispatch(incrementTriggerExport())
    }
  }

  const showManagePlanLinks = !isFinalized
  const showViewApprovedPlan = !isTimeline && isFinalized && displayState === "original"
  const showViewOriginalProposal = !isTimeline && isFinalized && displayState === "approved"

  const showMenu =
    showManagePlanLinks ||
    showViewApprovedPlan ||
    showViewOriginalProposal ||
    showExportButton ||
    showFinalizeButton

  if (!showMenu) return null

  const exportButtonId =
    typeof csvDownloadRef === "string" ? csvDownloadRef : csvDownloadRef?.current?.id
  const exportButtonRef = typeof csvDownloadRef === "string" ? undefined : csvDownloadRef ?? setRef

  const onlyExportAvailable =
    showExportButton &&
    !(showManagePlanLinks || showFinalizeButton || showViewApprovedPlan || showViewOriginalProposal)

  return (
    <div>
      <button
        ref={refs.setReference}
        type="button"
        id="plan-topbar-menu-button"
        className={cn("btn--large btn--secondary dropdown-link w-11", {
          "sm:hidden": onlyExportAvailable,
        })}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Menu"
      >
        <FontAwesomeIcon icon={["far", "ellipsis"]} />
      </button>
      {isOpen ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="dropdown-menu open z-20 mt-1"
          >
            {showManagePlanLinks ? (
              <ManagePlanLinks headcountPlanId={headcountPlanId} setBudget={setBudget} />
            ) : null}
            {showViewApprovedPlan ? (
              <button
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid"
                type="button"
                onClick={() => showApprovedView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["far", "circle-check"]} />
                </div>
                <span>{t("v2.headcount_plan.header.view_approved_plan")}</span>
              </button>
            ) : null}
            {showViewOriginalProposal ? (
              <button
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid"
                type="button"
                onClick={() => showOriginalView()}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["far", "undo"]} />
                </div>
                {t("v2.headcount_plan.header.view_original_proposal")}
              </button>
            ) : null}
            {showExportButton ? (
              <button
                id={exportButtonId}
                ref={exportButtonRef}
                className="dropdown-menu-link grid-cols-[1.25rem_auto] gap-2 grid sm:hidden"
                type="button"
                onClick={handleExportButtonClick}
              >
                <div className="justify-self-center">
                  <FontAwesomeIcon icon={["fal", "download"]} />
                </div>
                {t("v2.defaults.export")}
              </button>
            ) : null}
            {showFinalizeButton ? (
              <FinalizePlanButton
                className="flex sm:hidden"
                display="menuItem"
                canBeFinalized
                setIsOpen={setFinalizePlanModalIsOpen}
                onClick={() => setIsOpen(false)}
              />
            ) : null}
          </div>
        </FloatingPortal>
      ) : null}
      <ClientOnly>
        <FinalizePlanModal
          headcountPlanId={headcountPlanId}
          headcountPlanName=""
          isOpen={finalizePlanModalIsOpen}
          setIsOpen={setFinalizePlanModalIsOpen}
        />
      </ClientOnly>
    </div>
  )
}

function ManagePlanLinks({
  headcountPlanId,
  setBudget,
}: {
  headcountPlanId: string
  setBudget?: boolean
}) {
  const { t } = useTranslation()
  return (
    <>
      <a
        href={UrlHelper.headcountPlanSetupPath(headcountPlanId)}
        className="dropdown-menu-link gap-2 flex"
      >
        <div className="justify-self-center">
          <FontAwesomeIcon icon={["far", "gear"]} />
        </div>
        <span>{t("v2.headcount_plan.header.edit_plan_settings")}</span>
      </a>
      <a
        href={UrlHelper.headcountPlanInvitationsPath(headcountPlanId)}
        className="dropdown-menu-link gap-2 flex"
      >
        <div className="justify-self-center">
          <FontAwesomeIcon icon={prepareIconClass("fas users-custom")} />
        </div>
        <span>{t("v2.headcount_plan.header.manage_participants")}</span>
      </a>
      {setBudget && (
        <a
          href={UrlHelper.headcountPlanSetBudgetsPath(headcountPlanId)}
          className="dropdown-menu-link gap-2 flex"
        >
          <div className="justify-self-center">
            <FontAwesomeIcon icon={["far", "usd-square"]} />
          </div>
          <span>{t("v2.headcount_plan.header.edit_budgets")}</span>
        </a>
      )}
    </>
  )
}
