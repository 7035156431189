import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

import { CSV_DOWNLOAD_REF } from "v2/react/components/headcountPlanning/Owner/OwnerHeader"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { useGetOwnerDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { withRootProvider } from "../RootProvider"
import { TimelineView } from "./shared/TimelineView"

export function Content({ headcountPlanId }: { headcountPlanId: string }) {
  const ownerPage = useGetOwnerDatasheetPageQuery({ headcountPlanId })
  const headcountPlan = ownerPage.data?.headcountPlan
  const { t } = useTranslation()
  const csvDownloadName = [
    headcountPlan?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYYMMDD"),
  ].join("_")

  if (ownerPage.isLoading) return <Spinner />

  return (
    <TimelineView
      headcountPlanId={headcountPlanId}
      csvDownloadRef={CSV_DOWNLOAD_REF}
      csvDownloadName={csvDownloadName}
    />
  )
}

const OwnerTimeline = withRootProvider(Content, "OwnerTimeline")

export { OwnerTimeline }
