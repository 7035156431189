import React, { useState } from "react"

import { useNavController } from "v2/react/shared/navigation/useNavController"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface NavToggleProps {
  expanded: boolean
  barsOpenUrl: string
  barsClosedUrl: string
  expandedLabel: string
  collapsedLabel: string
}

function NavToggle({
  expanded,
  barsOpenUrl,
  barsClosedUrl,
  expandedLabel,
  collapsedLabel,
}: NavToggleProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const { toggleNav } = useNavController()

  const handleClick = () => {
    toggleNav()
    setShowTooltip(false)
  }

  const isExpanded = getExpandedState(expanded)
  const tooltipLabel = isExpanded ? expandedLabel : collapsedLabel

  return (
    <Tooltip open={showTooltip} placement="right">
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="block"
      >
        <button
          onClick={handleClick}
          type="button"
          className="btn--icon btn--ghost nav-toggle group ml-auto transition-all hover:bg-neutral-3"
        >
          <div className="nav-toggle--inner">
            <img
              src={barsOpenUrl}
              alt="Navigation Toggle"
              className="open-icon opacity-[.64] group-hover:opacity-100"
            />
            <img
              src={barsClosedUrl}
              alt="Navigation Toggle"
              className="closed-icon opacity-[.64] group-hover:opacity-100"
            />
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span className="break-all">{tooltipLabel}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const getExpandedState = (expanded: boolean) => {
  let navExpanded: boolean = expanded
  if (typeof document !== "undefined") {
    const nav = document.querySelector(".nav")
    navExpanded = !nav?.classList.contains("shrink-nav")
  }

  return navExpanded
}

export type { NavToggleProps }
export { NavToggle }
