import * as d3 from "d3-hierarchy"
import OrgChart from "org_chart/chart/orgChart"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"
import React from "react"

import { HeadcountPlanChangeProjection } from "types/graphql.d"
import { InProgressAlert } from "v2/react/components/headcountPlanning/shared/InProgressAlert"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { getCookie } from "v2/react/utils/cookies"
import { getVisualHeight } from "v2/react/utils/layout"
import { useGetOwnerOrgChartQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { useAppSelector } from "v2/redux/store"

RelationalNodeDataStore.load({ keys: {}, index: {} })

interface Props {
  headcountPlanId: string
}

function OwnerOrgChartInner({ headcountPlanId }: Props) {
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const displayState =
    useAppSelector((state) => state.headcountPlan.displayState) ?? displayStateCookie ?? "approved"
  const ownerPage = useGetOwnerOrgChartQuery({ headcountPlanId })
  const currentCompany = ownerPage.data?.currentCompany
  const headcountPlan = ownerPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const hcpPositions =
    isFinalized && displayState === "approved"
      ? headcountPlan?.approvedPositionsForOrgchart
      : headcountPlan?.allPositionsForOrgchart

  if (ownerPage.isLoading || !hcpPositions || !headcountPlan) return <Spinner />

  // Reset contents of org chart container
  const container = document.getElementById("organize-container")
  if (container) container.innerHTML = ""

  const stratify = d3
    .stratify()
    .id((d): string => (d as HeadcountPlanChangeProjection).id)
    .parentId((d): string => {
      const node = d as HeadcountPlanChangeProjection
      if (node.id === "company") return ""
      const reportsToId = node.positionAttributesWithEdits?.reports_to?.id || "company"
      if (reportsToId && reportsToId !== "company") {
        return `position_${reportsToId}`
      }
      return reportsToId
    })

  const positions = [...hcpPositions]
  const company = {
    id: "company",
    name: currentCompany?.name,
    avatar: currentCompany?.logoThumbUrl,
    klass: "Company",
  }
  positions.push(company)
  const chartData = stratify(positions)
  const chartOptions = {
    jsonData: chartData,
    loadAsync: true,
    positionsEndpoint: `/api/app/v1/headcount_plans/${headcountPlanId}/organize?display_state=${displayState}`,
    orgchartLite: true,
    displayFields: ["avatar", "name", "title"],
    showLabels: true,
    dragAndDropEnabled: false,
  }

  const chart = new OrgChart("#organize-container", chartOptions)
  chart?.loadFromJSON()

  return (
    <>
      {!headcountPlan.canBeFinalized && <InProgressAlert />}
      <div className="relative">
        <OrgChartViewOptions chart={chart} />
      </div>
    </>
  )
}

const OwnerOrgChart = ({ headcountPlanId }: Props) => (
  <RootProvider>
    <OwnerOrgChartInner headcountPlanId={headcountPlanId} />

    <div
      className="organize-container bg-primary-3-solid"
      id="organize-container"
      style={{ height: getVisualHeight() }}
    />
  </RootProvider>
)

export { OwnerOrgChart }
